import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import { Seo } from "../components/seo"

import { HeroDelivery } from "../components/heros/HeroDelivery"

import DeliveryPdf from "../images/delivery/CHLS_Account_Credit_Form_fill.pdf"
import SkipLogo from "../images/home/skip-the-dishes-logo.svg"
import UberLogo from "../images/home/uber-eats-logo.svg"

const Fc = styled.div`
  max-width: var(--size-1920);
  margin: 0 auto;
  display: grid;
  grid-template-areas:
    "boxA"
    "boxB"
    "boxC"
    "boxE"
    "boxD";
  
  .boxA,
  .boxC {
    margin: var(--space-4);
  }
  .boxA {
    grid-area: boxA;
  }
  .boxB {
    grid-area: boxB;
  }
  .boxC {
    grid-area: boxC;

    text-align: center;
  }
  .boxD {
    grid-area: boxD;
  }

  .boxC__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1130px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;

    grid-template-areas:
      "boxA boxB"
      "boxD boxC";
  }

  .boxA,
  .boxC {
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .boxC__logo {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const DeliveryPage = () => (
  <Layout>
    <HeroDelivery />
    <div>
      <h1>DELIVERY</h1>
    </div>
    <Fc>
      <div className="boxA">
        <h2 className="textLeft">CORPORATE ACCOUNTS</h2>
        <p>
          12:00PM - 4:30PM TUESDAY TO FRIDAY
          <br />
          No delivery charges for corporate accounts
          <br />
          Accounts set up by filling in form below
          <br />
          Or call:{" "}
          <a href="tel:6046851212" title="call 604 685 1212">
            +1 604 685 1212
          </a>
        </p>
        <button className="button">
          <a href={DeliveryPdf} title="download or open the delivery pdf" target="_blank" rel="noopener noreferrer">
            ACCOUNT SIGN-UP FORM (PDF)
          </a>
        </button>
      </div>
      <div className="boxB">
        <div>
          <StaticImage
            src="../images/delivery/downtown.jpg"
            alt="coal harbour alternate pic"
            aspectRatio={16 / 9}
          />
        </div>
      </div>
      <div className="boxC">
        <h2>RESIDENTIAL & HOTEL</h2>
        <p>We deliver through Uber Eats & Skip the Dishes</p>
        <div className="boxC__logo">
          <a href="https://www.skipthedishes.com/coal-harbour-liquor-store?utm_source=google&utm_medium=organic&utm_campaign=gpa"
             target="_blank"
             rel="noreferrer noopener"
             title="skip the dishes for coal harbour liquor store">
            <img
              src={SkipLogo}
              height="auto"
              width="auto"
              alt="skip the dishes logo"
            />
          </a>
          <a href="https://www.ubereats.com/ca/store/coal-harbour-liquor-store/kgorj5_7Q0SZ9JeURh7ZHg"
             target="_blank"
             rel="noreferrer noopener"
             title="uber eats for coal harbour liquor store">
            <img src={UberLogo} height="auto" width="auto" alt="uber eats logo" />
          </a>

        </div>
      </div>
      <div className="boxD">
        <div>
          <StaticImage
            src="../images/delivery/harbour-2.jpg"
            alt="coal harbour alternate pic"
            aspectRatio={16 / 9}
          />
        </div>
      </div>
    </Fc>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Coal Harbour Liquor Delivery Options" description="Check here for Residential, Hotel and Corporate delivery instructions." canonical="https://coalharbourliquorstore.com/delivery/" />

export default DeliveryPage
