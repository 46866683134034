import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styled from "@emotion/styled"

export function HeroDelivery() {
  const Wrapper = styled.div`
    display: grid;
    height: calc(100vh - 100px);
    margin-bottom: var(--space-4);

    .heroImg {
      grid-area: 1 / 1;
      z-index: 0;
    }

    .heroBoxB {
      z-index: 1;
      grid-area: 1 / 1;
      position: relative;
      place-items: center;
      display: grid;
    }

    .heroText {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  `

  return (
    <Wrapper>
      <StaticImage
        className="heroImg"
        layout="fullWidth"
        alt="coal harbour liquor store harbour"
        src={"../../images/delivery/harbour.jpg"}
        loading="eager"
      />
      <div className="heroBoxB">
        <div className="heroText">{/* intentionally blank */}</div>
      </div>
    </Wrapper>
  )
}
